import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';

const formatAmounts = (data) => {
  return data.map((item) => ({
    ...item,
    subscription: item.subscription / 100,
    sales: item.sales / 100,
    commission: (item.sales / 100) * 0.05, // Calculate 5% commission on sales
    totalEarnings: item.subscription / 100 + (item.sales / 100) * 0.05 // Sum of subscription and commission
  }));
};

const IncomeGraph = ({ data }) => {
  const [formattedData, setFormattedData] = useState(formatAmounts(data));

  useEffect(() => {
    const formatted = formatAmounts(data);
    setFormattedData(formatted);
  }, [data]);

  const formatYAxis = (value) => {
    return `$${Number(value).toLocaleString()}`;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={formattedData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis tickFormatter={formatYAxis} domain={[0, 'auto']} />
        <Tooltip
          formatter={(value, name) => {
            const labels = {
              subscription: 'Subscription',
              sales: 'Sales',
              commission: 'Commission (≈5%)',
              totalEarnings: 'Total Earnings'
            };
            return [`$${Number(value).toLocaleString()}`, labels[name] || name];
          }}
          labelFormatter={(label) => `Month: ${label}`}
        />
        <Legend />
        <Bar dataKey="sales" fill="#82ca9d" />
        <Bar dataKey="commission" name="Commission (≈5%)" fill="#ff7f50" />
        <Bar dataKey="subscription" fill="#8884d8" />
        <Bar dataKey="totalEarnings" name="Total Earnings" fill="#4169e1" />
      </BarChart>
    </ResponsiveContainer>
  );
};

IncomeGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      month: PropTypes.string.isRequired,
      subscription: PropTypes.number.isRequired,
      sales: PropTypes.number.isRequired
    })
  ).isRequired
};

export default IncomeGraph;
