import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AccountService from '../../services/account.service';
import Modal from '../subcomponents/Modal';
import { TextField, DialogActions, DialogContent, Button } from '@mui/material';

const TrialModal = ({ open, onClose, accountId, trialDuration, onSuccess }) => {
  const [trialDays, setTrialDays] = useState(0);

  useEffect(() => {
    setTrialDays(0);
  }, [trialDuration]);

  const handleClose = () => {
    setTrialDays(trialDuration);
    onClose();
  };

  const handleUpdateTrial = () => {
    const daysToAdd = parseInt(trialDays) + trialDuration;
    AccountService.updateTrial(accountId, daysToAdd)
      .then(() => {
        onSuccess();
        handleClose();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Modal open={open} title={'Add Trial Days'}>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Trial Days to add"
          type="number"
          fullWidth
          variant="outlined"
          value={trialDays}
          onChange={(e) => setTrialDays(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleUpdateTrial} variant="contained">
          Update
        </Button>
      </DialogActions>
    </Modal>
  );
};

TrialModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired,
  trialDuration: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default TrialModal;
