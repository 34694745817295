import React, { useEffect, useState } from 'react';
import { Grid, Card, CardContent, Typography, CircularProgress, Box } from '@mui/material';
import DashboardService from '../services/dashboard.service';
import RegistrationGraph from './dashboard/RegistrationGraph';
import IncomeGraph from './dashboard/IncomeGraph';

const initialOverview = {
  newAccounts: 0,
  newSubscribers: 0,
  newProducts: 0,
  salesCount: 0,
  salesAmount: 0
};

const initialRegistrationData = [];
const initialIncomeData = [];

const Dashboard = () => {
  const [overview, setOverview] = useState(initialOverview);
  const [registrationData, setRegistrationData] = useState(initialRegistrationData);
  const [incomeData, setIncomeData] = useState(initialIncomeData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    DashboardService.get()
      .then((response) => {
        if (response.data.success) {
          setOverview(response.data.overview);
          setRegistrationData(response.data.last6Months);
          setIncomeData(response.data.last6MonthsIncome);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <>
      <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
        Overview
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div">
                New Signups this Month
              </Typography>
              <Typography variant="h4">{overview.newAccounts}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div">
                New Subscribers this Month
              </Typography>
              <Typography variant="h4">{overview.newSubscribers}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div">
                New Products this Month
              </Typography>
              <Typography variant="h4">{overview.newProducts}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div">
                Quantity of Sales this Month
              </Typography>
              <Typography variant="h4">{overview.salesCount}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" component="div">
                Amount of Sales this Month
              </Typography>
              <Typography variant="h4">${(overview.salesAmount / 100).toFixed(2)}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={6} sx={{ height: '400px' }}>
          <Typography variant="h5" component="h2" sx={{ mb: 3, textAlign: 'center' }}>
            Signups & Conversions Trends
          </Typography>
          <RegistrationGraph data={registrationData} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ height: '400px' }}>
          <Typography variant="h5" component="h2" sx={{ mb: 3, textAlign: 'center' }}>
            Income Trends
          </Typography>
          <IncomeGraph data={incomeData} />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
